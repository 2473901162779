import { ChangeDetectorRef, Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppWebBridgeService } from '@services/app-web-bridge-service';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import SwiperCore, { Scrollbar, SwiperOptions, EffectFade } from 'swiper';
import { AppConfig } from '../app.config';

SwiperCore.use([EffectFade]);

@Component({ selector: 'introduction', templateUrl: './introduction.html' })
export class IntroductionComponent {
  ui: any = { loading: true };
  showBtn: boolean = false;
  language: any;
  url: SafeResourceUrl;
  carouselConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: { el: '.swiper-pagination' },
    navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
    effect: 'fade',
    fadeEffect: { crossFade: true },
    loop: true,
    autoplay: {
      delay: 4000, // 4 seconds delay
      disableOnInteraction: false, // Allow autoplay even when manually interacting with Swiper
    },
  };
  endOfSlide: boolean = false;
  currentSlide: number = 0;
  constructor(private router: Router,
    public conn: ConnectionService,
    private appConfig: AppConfig,
    private dom: DomSanitizer,
    private changeDetectionRef: ChangeDetectorRef,
    private appWebBridge: AppWebBridgeService,
    private eventLogger: EventLoggerService,
  ) {
    this.language = this.conn.currentWebSiteLanguage.toLowerCase();
    console.log('introduction page');
  }

  ngOnInit(): any {
    this.url = this.dom.bypassSecurityTrustResourceUrl(this.getVideoUrl());
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'onboarding-introduction' }));
  }

  getVideoUrl(): any {
    let videoURL = this.appConfig.Shared.IntroductionVideos[this.conn.currentWebSiteLanguage];
    if (!videoURL) videoURL = this.appConfig.Shared.IntroductionVideos[this.appConfig.Shared.Languages.EN];
    return videoURL;
  }

  load(): void {
    setTimeout((): void => {
      this.ui.loading = false;
      setTimeout((): boolean => this.showBtn = true, 9000);
    }, 500);
  }

  getStarted(): any {
    switch (this.currentSlide) {
      case 0: this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'introSliderSkip1' }));
        break;
      case 1: this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'introSliderSkip2' }));
        break;
      case 2: this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'introSliderContinue' }));
        break;
      default: break;
    }

    this.conn.navigateToURL('/onboarding/number');
  }
}
