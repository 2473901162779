/* eslint-disable max-len */
import { Routes } from '@angular/router';
import { ChoosePlanInfoComponent } from '@shared/choose-plan-info/choose-plan-info.component';
import { TestimonialCaseStudiesInfoComponent } from '@shared/testimonials/testimonial-case-studies/testimonial-case-studies-info/testimonial-case-studies-info.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { EmptyComponent } from './empty/empty.component';
import { LoginDoctorComponent } from './login/doctor/login-doctor.component';
import { OnboardingGenderComponent } from './onboarding/onboarding-gender/onboarding-gender.component';
import { OnboardingMobileNumberComponent } from './onboarding/onboarding-number/onboarding-mobile-number.component';
import { OnboardingMobileOtpComponent } from './onboarding/onboarding-otp/onboarding-mobile-otp.component';
import { OnboardingConcernComponent } from './onboarding/onboarding-concern/onboarding-concern.component';
import { LogoutComponent } from './logout/logout.component';
import { MainConcernComponent } from './main-concern/main-concern.component';
import { UserFollowupReportComponent } from './user/user-followup-report/user-followup-report.component';
import { AboutComponent } from './about/about.component';
import { HelpComponent } from './help/help.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FaqsComponent } from './faqs/faqs.component';
import { UserViewComponent } from './user/user-view/user-view.component';
import { UserCheckoutComponent } from './user/user-checkout/user-checkout.component';
import { UserOrderListComponent } from './user/user-order/user-order-list/user-order-list.component';
import { UserOrderReorderComponent } from './user/user-order/user-order-reorder/user-order-reorder.component';
import { UserOrderSuccessComponent } from './user/user-order/user-order-success/user-order-success.component';
import { UserOrderFailureComponent } from './user/user-order/user-order-failure/user-order-failure.component';
import { UserOrderViewComponent } from './user/user-order/user-order-view/user-order-view.component';
import { UserOrderPaymentComponent } from './user/user-order/user-order-payment/user-order-payment.component';
import { UserPrescriptionViewComponent } from './user/user-prescription/user-prescription-view.component';
import { UserProfileEditComponent } from './user/user-profile/user-profile-edit/user-profile-edit.component';
import { UserReferComponent } from './user/user-refer/user-refer.component';
import { ArticleComponent } from './article/article.component';
import { OnboardingLanguageComponent } from './onboarding-language/onboarding-language.component';
import { UserProfileComponent } from './user/user-profile/user-profile/user-profile.component';
import { IframeComponent } from './iframe/iframe.component';
import { UserWalletViewComponent } from './user/user-wallet/user-wallet-view/user-wallet-view.component';
import { UserFollowupComponent } from './user/user-followup/user-followup/user-followup.component';
import { NoticeComponent } from './notice/notice.component';
import { UserWalletTransactionsComponent } from './user/user-wallet/user-wallet-transactions/user-wallet-transactions.component';
import { UserDietComponent } from './user/user-diet/user-diet.component';
import { VideoComponent } from './video/video.component';
import { CureskinStarTermsComponent } from './cureskin-star/cureskin-star-terms/cureskin-star-terms.component';
import { UserOrderAddonComponent } from './user/user-order/user-order-addon/user-order-addon.component';
import { UserFollowupReminderComponent } from './user/user-followup/user-followup-reminder/user-followup-reminder.component';
import { GameComponent } from './game/game.component';
import { UserRegimenProductsViewComponent }
  from './user/user-regimen/user-regimen-products-view/user-regimen-products-view.component';
import { DoctorViewComponent } from './doctor/doctor-view/doctor-view.component';
import { UserRegimenDoctorComponent } from './user/user-regimen/user-regimen-doctor/user-regimen-doctor.component';
import { UserRegimenServicesComponent } from './user/user-regimen/user-regimen-services/user-regimen-services.component';
import { OnboardingTakePhotoComponent } from './onboarding/onboarding-take-photo/onboarding-take-photo.component';
import { OnboardingCheckupDetectionsComponent } from './onboarding/onboarding-checkup-detections/onboarding-checkup-detections.component';
import { DoctorListComponent } from './doctor/doctor-list/doctor-list.component';
import { CureskinStarComponent } from './cureskin-star/cureskin-star/cureskin-star.component';
import { UserAddressEditComponent } from './user/user-address/user-address-edit/user-address-edit.component';
import { UserAddressListComponent } from './user/user-address/user-address-list/user-address-list.component';
import { SupportComponent } from './support/support.component';
import { SupportTicketListComponent } from './support/support-ticket-list/support-ticket-list.component';
import { ReminderFullPageComponent } from './reminder';
import { ChatV2Component } from './chatV2/chatV2.component';
import { UserNotificationComponent } from './user/user-view/user-notification/user-notification.component';
import { UserCheckupComponent } from './user/user-checkup/user-checkup.component';
import { ProductDescriptionComponent } from './product-description/product-description.component';
import { ProductCartComponent } from './cart/cart.component';
import { InstaLiveInfoComponent } from './insta-live-info/insta-live-info.component';
import { OnboardingLocationComponent } from './onboarding/onboarding-location/onboarding-location.component';
import { UserLiveComponent } from './user/user-live/user-live/user-live.component';
import { QuizGameComponent } from './quiz-game/quiz-game.component';
import { TestimonialsNewComponent } from './testimonials-new/testimonials-new.component';
import { TestimonialsDescComponent } from './testimonials-description/testimonials-description.component';
import { ReportComponent } from './report/report.component';
import { ReAcquisitionTakePhotoComponent } from './re-acquisition-flow/re-acquisition-take-photo/re-acquisition-take-photo.component';
import { ReAcquisitionUserQueryComponent } from './re-acquisition-flow/re-acquisition-user-query/re-acquisition-user-query.component';
import { ReAcquisitionUserWaitingForCallComponent } from './re-acquisition-flow/re-acquisition-waiting-for-call/re-acquisition-waiting-for-call.component';
import {
  CheckupCaptureComponent,
  CheckupListComponent,
  CheckupPhotoInstructionComponent,
  CheckupViewContainerComponent,
  SelectCheckupTypeComponent,
} from './instant-checkup/components';
import { authenticationGuard } from './guards/authentication.guard';
import { onboardingGuard } from './guards/onboarding.guard';

const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [authenticationGuard],
    redirectTo: '/user?tab=home&init=true',
  },
  {
    path: 'language',
    component: OnboardingLanguageComponent,
  },
  {
    path: 'introduction',
    component: IntroductionComponent,
  },
  {
    path: 'onboarding',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/',
      },
      {
        path: 'gender',
        canActivate: [authenticationGuard],
        component: OnboardingGenderComponent,
      },
      {
        path: 'number',
        component: OnboardingMobileNumberComponent,
      },
      {
        path: 'otp',
        component: OnboardingMobileOtpComponent,
      },
      {
        path: 'concern',
        canActivate: [authenticationGuard],
        component: OnboardingConcernComponent,
      },
      {
        path: 'take-photo',
        canActivate: [authenticationGuard],
        component: OnboardingTakePhotoComponent,
      },
      {
        path: 'checkup-detections',
        canActivate: [authenticationGuard],
        component: OnboardingCheckupDetectionsComponent,
      },
      {
        path: 'location',
        canActivate: [authenticationGuard],
        component: OnboardingLocationComponent,
      },
    ],
  },
  {
    path: 're-acquisition',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: 'take-photo',
        component: ReAcquisitionTakePhotoComponent,
      },
      {
        path: 'user-query',
        component: ReAcquisitionUserQueryComponent,
      },
      {
        path: 'waiting-for-call',
        component: ReAcquisitionUserWaitingForCallComponent,
      },
    ],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'cart',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ProductCartComponent,
  },
  {
    path: 'insta-live',
    canActivate: [authenticationGuard, onboardingGuard],
    component: InstaLiveInfoComponent,
  },
  {
    path: 'notice',
    canActivate: [authenticationGuard, onboardingGuard],
    component: NoticeComponent,
  },
  {
    path: 'video',
    canActivate: [authenticationGuard, onboardingGuard],
    component: VideoComponent,
  },
  {
    path: 'mainConcern',
    canActivate: [authenticationGuard],
    component: MainConcernComponent,
  },
  {
    path: 'progress/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    redirectTo: '/user/report/:id',
  },
  {
    path: 'report',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ReportComponent,
  },
  {
    path: 'about',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: '',
        component: AboutComponent,
      },
      {
        path: 'cureskin',
        component: AboutComponent,
      },
    ],
  },
  {
    path: 'doctor',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: ':id',
        component: DoctorViewComponent,
      },
    ],
  },
  {
    path: 'doctors',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: '',
        component: DoctorListComponent,
      },
    ],
  },
  {
    path: 'about-us',
    canActivate: [authenticationGuard, onboardingGuard],
    component: AboutComponent,
  },
  {
    path: 'support',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: '',
        component: SupportComponent,
      },
      {
        path: 'ticket/:id',
        component: ChatV2Component,
      },
      {
        path: 'tickets',
        component: SupportTicketListComponent,
      },
      {
        path: ':id',
        component: SupportComponent,
      },
    ],
  },
  {
    path: 'help',
    canActivate: [authenticationGuard, onboardingGuard],
    component: HelpComponent,
  },
  {
    path: 'game',
    canActivate: [authenticationGuard, onboardingGuard],
    component: GameComponent,
  },
  {
    path: 'quiz-game',
    canActivate: [authenticationGuard, onboardingGuard],
    component: QuizGameComponent,
  },
  {
    path: 'feedback',
    canActivate: [authenticationGuard, onboardingGuard],
    component: FeedbackComponent,
  },
  {
    path: 'feedback/:category',
    canActivate: [authenticationGuard, onboardingGuard],
    component: FeedbackComponent,
  },
  {
    path: 'login/doctor',
    canActivate: [authenticationGuard, onboardingGuard],
    component: LoginDoctorComponent,
  },
  {
    path: 'empty',
    canActivate: [authenticationGuard, onboardingGuard],
    component: EmptyComponent,
  },
  {
    path: 'addonProduct/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ArticleComponent,
  },
  {
    path: 'article/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ArticleComponent,
  },
  {
    path: 'product/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ProductDescriptionComponent,
  },
  {
    path: 'faq',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: '',
        component: FaqsComponent,
      },
      {
        path: ':id',
        component: FaqsComponent,
      },
    ],
  },
  {
    path: 'cureskin-star',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: '',
        component: CureskinStarComponent,
      },
      {
        path: 'terms',
        component: CureskinStarTermsComponent,
      },
    ],
  },
  {
    path: 'iframe',
    canActivate: [authenticationGuard, onboardingGuard],
    component: IframeComponent,
  },
  {
    path: 'chatV2',
    children: [
      {
        path: ':id',
        component: ChatV2Component,
      },
    ],
  },
  {
    path: 'user',
    canActivate: [authenticationGuard, onboardingGuard],
    children: [
      {
        path: '',
        component: UserViewComponent,
        pathMatch: 'full',
      },
      {
        path: 'testimonialsNew',
        component: TestimonialsNewComponent,
        pathMatch: 'full',
      },
      {
        path: 'testimonialsNew/:type/:testimonialId',
        component: TestimonialsDescComponent,
        pathMatch: 'full',
      },
      {
        path: 'caseStudies/:caseStudyId',
        component: TestimonialCaseStudiesInfoComponent,
        pathMatch: 'full',
      },
      {
        path: 'checkout',
        component: UserCheckoutComponent,
      },
      {
        path: 'followups',
        component: UserFollowupComponent,
      },
      {
        path: 'notifications',
        component: UserNotificationComponent,
      },
      {
        path: 'followup',
        children: [
          {
            path: 'reminder',
            component: UserFollowupReminderComponent,
          },
        ],
      },
      {
        path: 'regimen',
        children: [
          {
            path: 'products',
            component: UserRegimenProductsViewComponent,
          },
          {
            path: 'doctor',
            component: UserRegimenDoctorComponent,
          },
          {
            path: 'services',
            component: UserRegimenServicesComponent,
          },
          {
            path: 'plan-info',
            component: ChoosePlanInfoComponent,
          },
        ],
      },
      {
        path: 'address',
        children: [
          {
            path: '',
            component: UserAddressListComponent,
          },
          {
            path: ':id/edit',
            component: UserAddressEditComponent,
          },
          {
            path: 'new',
            component: UserAddressEditComponent,
          },
        ],
      },
      {
        path: 'addresses',
        component: UserAddressListComponent,
      },
      {
        path: 'report',
        children: [
          {
            path: ':id',
            component: UserFollowupReportComponent,
          },
        ],
      },
      {
        path: 'instantCheckups',
        children: [
          {
            path: '',
            redirectTo: '/user/instantCheckup/list',
            pathMatch: 'full',
          },
          {
            path: 'preview',
            redirectTo: '/user/instantCheckup/preview',
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'instantCheckup',
        children: [
          {
            path: '',
            component: SelectCheckupTypeComponent,
          },
          {
            path: 'menu',
            component: SelectCheckupTypeComponent,
          },
          {
            path: 'preview',
            component: CheckupPhotoInstructionComponent,
          },
          {
            path: 'capture',
            component: CheckupCaptureComponent,
          },
          {
            path: 'list',
            component: CheckupListComponent,
          },
          {
            path: ':id',
            component: CheckupViewContainerComponent,
          },
        ],
      },
      {
        path: 'orders',
        canActivate: [authenticationGuard, onboardingGuard],
        children: [
          {
            path: '',
            component: UserOrderListComponent,
          },
          {
            path: 'success',
            component: UserOrderSuccessComponent,
          },
          {
            path: 'failure',
            component: UserOrderFailureComponent,
          },
          {
            path: 'reorder',
            component: UserOrderReorderComponent,
          },
        ],
      },
      {
        path: 'order',
        canActivate: [authenticationGuard, onboardingGuard],
        children: [
          {
            path: 'list',
            redirectTo: '/user/orders',
            pathMatch: 'full',
          },
          {
            path: '',
            component: UserOrderListComponent,
          },
          {
            path: 'reorder',
            component: UserOrderReorderComponent,
          },
          {
            path: 'success',
            component: UserOrderSuccessComponent,
          },
          {
            path: 'failure',
            component: UserOrderFailureComponent,
          },
          {
            path: 'addon',
            component: UserOrderAddonComponent,
          },
          {
            path: ':id/payment',
            component: UserOrderPaymentComponent,
          },
          {
            path: ':id',
            component: UserOrderViewComponent,
          },
        ],
      },
      {
        path: 'prescription',
        component: UserPrescriptionViewComponent,
      },
      {
        path: 'refer',
        component: UserReferComponent,
      },
      {
        path: 'wallet',
        children: [
          {
            path: '',
            component: UserWalletViewComponent,
          },
          {
            path: 'transactions',
            component: UserWalletTransactionsComponent,
          },
        ],
      },
      {
        path: 'profile',
        children: [
          {
            path: '',
            component: UserProfileComponent,
          },
          {
            path: 'edit',
            component: UserProfileEditComponent,
          },
        ],
      },
      {
        path: 'diet/:id',
        component: UserDietComponent,
      },
      {
        path: 'checkup',
        component: UserCheckupComponent,
      },
      {
        path: 'live-session',
        component: UserLiveComponent,
      },
    ],
  }, {
    path: 'reminder/:id',
    canActivate: [authenticationGuard, onboardingGuard],
    component: ReminderFullPageComponent,
    outlet: 'reminder',
  }, {
    path: '**',
    canActivate: [authenticationGuard, onboardingGuard],
    redirectTo: '/',
  },
];

const declarations: any = [];

export { AppRoutes, declarations };
