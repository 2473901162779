/* eslint-disable complexity */
import { Component, DoCheck, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Models } from 'api-client';
import { fromSelectors } from '@store/selectors';
import { fromActions } from '@store/actions';
import { ConnectionService } from '@services/connection-service';
import { EventLoggerService } from '@services/event-logger-service';
import { BroadcastService } from '@services/broadcast-service';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { AnimationOptions } from 'ngx-lottie';
import { LocalStorageService } from '@services/local-storage-service';
import { WindowRefService } from '@services/window-ref-service';
import { CacheService } from '@services/cache-service';
import { AppConfig } from '../../../app.config';

@Component({ selector: 'user-order-reorder',
  templateUrl: './user-order-reorder.html',
  styleUrls: ['./user-order-reorder.scss'] })

export class UserOrderReorderComponent implements OnDestroy, DoCheck {
  subscriptions: Array<Subscription>;
  productsAvailable: Array<any> = [];
  totalSP: number = 0;
  totalMRP: number = 0;
  discount: number = 0;
  nonRxLimit: number = 5;
  rxLimit: number = 3;
  maxPrice: number;
  drRecommendedProducts: any;
  cartValue: number;
  allShopProducts: any = [];
  isBOGOSaleLive: boolean = false;
  isHoliSaleLive: boolean = false;
  isSearchOn: boolean = false;
  isAdvancedSearchOn: boolean = false;
  allSaleProducts: any = [];
  allKitsProducts: any = [];
  holiSaleProducts: any = [];
  searchProducts: any = [];
  percentageDone: number;
  saleVariants: any;
  holiSaleVariants: any;
  searchKey: string;
  amountRemaining: any;
  aovTask: boolean = false;
  prodImage: any;
  loading: boolean = true;
  barStyle: any = {};
  tipStyle: any = {};
  freeProduct: any;
  cart: any;
  cartItems: any = [];
  user: any;
  options: AnimationOptions = {
    path: '/assets/animation.json',
  };
  styles: Partial<CSSStyleDeclaration> = {
    marginTop: '-200px',
    position: 'sticky',
    bottom: '0',
  };
  animationProduct: any;
  showConfettiAnimation: boolean = false;
  aovData: any;
  scrollIdData: any;
  oldProduct: any;
  newProduct: any;
  selfReorderWarning: boolean;
  isDiscontinued: boolean = false;
  // productsInCart - map of products with id and quantity. ex: { 'xd03q56': 2 }
  productsInCart: { [key: string]: number } = {};
  toolbarTitle: string = 'Select Products';
  isReplaced: boolean = false;
  toolbarIcons: Array<any> = [{
    clickId: 'cart_page',
    name: this.appConfig.Toolbar.ICONS.CART,
    callback: (): void => this.goToCart(),
    cartValue: null,
  }];
  experiments: Array<any> = [];
  isPersonalizedBogoExperiment: boolean = false;
  personalizedBogoTitle: any;
  refactoredSections: Array<string> = ['reorderProducts', 'recommendedProducts', 'bodyProducts', 'scalpProducts', 'lipProducts'];
  tagCacheTableMapping: Record<string, string> = AppConfig.TagCacheTableMapping;

  constructor(private conn: ConnectionService,
    private router: Router,
    private route: ActivatedRoute,
    private eventLogger: EventLoggerService,
    private broadcast: BroadcastService,
    private appConfig: AppConfig,
    private location: Location,
    private windowRef: WindowRefService,
    private localStorage: LocalStorageService,
    private cacheService: CacheService,
    private store: Store) {
  }
  ngDoCheck(): void {
    const id = this.localStorage.getValue('scrollID');
    const windowRef = this.windowRef.nativeWindow;
    const doc = windowRef.document;
    const lifestyle = doc.getElementById(id);
    if (!this.loading && lifestyle) {
      lifestyle.scrollIntoView({ behavior: 'smooth', block: 'end' });
      this.localStorage.delete('scrollID');
    }
    if (this.newProduct?.objectId && this.isReplaced) {
      const replacedProductID = doc.getElementById(this.newProduct.objectId);
      if (!this.loading && replacedProductID) {
        replacedProductID.scrollIntoView({ block: 'end', behavior: 'smooth' });
        this.isReplaced = false;
      }
    }
  }

  async ngOnInit(): Promise<any> {
    this.subscriptions = [];
    this.user = this.conn.getActingUser();
    this.experiments = await this.conn.findUserActiveExperiments(this.user?.get('username'));
    this.toolbarIcons[0].showCartUI = true;
    this.experiments.forEach(async (experiment: any): Promise<any> => {
      if (experiment.key === 'buy1_get1_sale') {
        this.saleVariants = experiment.variant;
        this.isBOGOSaleLive = true;
      }
      if (experiment.key === 'perosonlized_bogo') {
        this.isPersonalizedBogoExperiment = true;
        this.personalizedBogoTitle = experiment.variant;
      }
      if (experiment.key === 'holi_sale') {
        this.holiSaleVariants = experiment.variant;
        this.isHoliSaleLive = true;
      }
      if (experiment.key === 'shop_search') {
        this.isSearchOn = true;
      }
      if (experiment.key === 'advanced_search') {
        this.isAdvancedSearchOn = true;
      }
      if (experiment.key === 'aov_gamification' && this.user?.get('orderState') === this.appConfig.Shared.User.OrderState.DELIVERED) {
        this.aovData = experiment.variant;
        this.maxPrice = this.aovData.totalValue;
        const product = await this.conn.findCatalogWithKey([this.aovData.productID]);
        [this.freeProduct] = JSON.parse(JSON.stringify(product));
        if (this.aovData?.productImage) {
          this.prodImage = this.aovData.productImage;
        } else if (this.freeProduct.rebrandedImageWithBackground?.length) {
          this.prodImage = this.freeProduct.rebrandedImageWithBackground[0];
        } else if (this.freeProduct?.rebrandedImageWithoutBackground?.length) {
          this.prodImage = this.freeProduct.rebrandedImageWithoutBackground[0];
        } else if (this.freeProduct?.productUnboxedImage?.length) {
          this.prodImage = this.freeProduct.productUnboxedImage[0];
        }
        this.aovTask = true;
        this.getCart();
      }
    });
    await this.getCart();
    this.store.select(fromSelectors.selectCartProducts).subscribe((products: any): any => this.productsInCart = { ...products });
    await this.loadData();
    this.eventLogger.trackWebPage();
    this.eventLogger.trackEvent('reorder_page', { username: this.user.get('username') });
    this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'product-list' }));
    this.eventLogger.trackInElasticSearch({ username: this.user.get('username'),
      added: new Date(),
      type: 'webApp',
      message: 'User into reorder page',
      event: 'REORDER_PAGE' });
    this.loading = false;
  }

  goToCart(): void {
    this.router.navigate(['cart']);
  }

  async getCart(): Promise<void> {
    this.cart = await this.conn.getCart();
    this.cartItems = this.cart?.get('lineItems');
    if (this.cartItems?.length) {
      this.toolbarIcons[0].cart = true;
      let totalItems: number = 0;
      this.cartItems.forEach((element: any): void => {
        totalItems += element.quantity;
      });
      this.toolbarIcons[0].cartValue = totalItems || null;
      if (this.aovTask) {
        this.percentageDone = Math.round((this.cart.get('totalPrice') / this.maxPrice) * 100);
        const foundTwo = this.cartItems.some((el: any): any => el.productId === this.aovData.productID && el.quantity > 1);
        if (this.cart.get('totalPrice') < this.maxPrice || foundTwo) {
          const found = this.cartItems.some((el: any): any => el.productId === this.aovData.productID);
          if (found) {
            const params1 = {
              productId: this.aovData.productID,
              quantity: 1,
            };
            await this.conn.removeProductFromCart(params1);
            this.getCart();
          }
          this.amountRemaining = this.maxPrice - this.cart.get('totalPrice');
          this.barStyle.width = `${this.percentageDone}%`;
          this.barStyle.backgroundColor = '#D66736';
        } else {
          this.barStyle.width = '100%';
          this.barStyle.backgroundColor = '#5EAC73';
          this.amountRemaining = 0;
          const found = this.cartItems.some((el: any): any => el.productId === this.aovData.productID);
          if (!found) {
            const params1 = {
              productId: this.aovData.productID,
              quantity: 1,
            };
            await this.conn.addProductToCart(params1);
            this.getCart();
            this.showConfettiAnimation = true;
            setTimeout((): any => {
              this.showConfettiAnimation = false;
            }, 2000);
          }
        }
      }
    } else {
      if (this.aovTask) {
        this.barStyle.width = '1%';
        this.barStyle.backgroundColor = '#D66736';
        const found = this.cartItems.some((el: any): any => el.productId === this.aovData.productID);
        if (found) {
          const params1 = {
            productId: this.aovData.productID,
            quantity: 1,
          };
          await this.conn.removeProductFromCart(params1);
          this.getCart();
        }
      }
      this.amountRemaining = this.maxPrice;
      this.toolbarIcons[0].cartValue = null;
    }
    if (this.aovTask) {
      const product = this.cartItems.find((each: any): any => each.price === 0);
      if (product?.quanity > 1) {
        const params1 = {
          productId: this.aovData.productID,
          quantity: 1,
        };
        await this.conn.removeProductFromCart(params1);
        this.getCart();
      }
    }
  }

  /**
   * Fetches are re-order'able products & addon products.
   * 1. If products id is mentioned in queryParam, then we by default add 1 quantity in cart for those products.
   * 2. If no products mentioned in params, then we show all products that are fetched.
   * 3. We sort it based on quantity, to bring product that are added in cart to focus.
   * 4. We calculate total MRP & SP for products in cart.
   * 5. We calculate discount applied on the total.
   */
  async loadData(): Promise<any> {
    const params = this.route.snapshot.queryParams;
    let tags: any[];
    let excludeTag: any[];
    let products: any = [];
    if (this.isSearchOn) {
      this.searchKey = params.searchKey;
      if (params.from === 'search' && params.searchKey) {
        this.eventLogger.trackInElasticSearch({
          event: 'PRODUCT_SEARCH',
          type: 'APP_SEARCH_LOG',
          key: this.searchKey,
          user: this.user.get('username'),
        });
        this.searchProducts = await this.conn.findProductsByText(params.searchKey);
        products = JSON.parse(JSON.stringify(this.searchProducts));
        this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ name: 'search-page-open' }));
      }
    }
    // if (this.isAdvancedSearchOn) {
    //   this.searchKey = params.searchKey;
    //   if (params.from === 'search' && params.searchKey) {
    //     this.eventLogger.trackInElasticSearch({
    //       event: 'PRODUCT_SEARCH_ADVANCED',
    //       type: 'APP_SEARCH_LOG',
    //       key: this.searchKey,
    //       user: this.user.get('username'),
    //     });
    //     this.searchProducts = await this.conn.advancedSearchProductsByText(params.searchKey);
    //     products = this.searchProducts;
    //   }
    // }
    if (params.from === 'saleProducts') {
      this.allSaleProducts = await this.conn.findSaleProds();
      this.allSaleProducts.sort((element1: any, element2: any): number => {
        if (element1.get('ranking') === element2.get('ranking')) {
          return 0;
        }
        return element1.get('ranking') > element2.get('ranking') ? 1 : -1;
      });
      products = this.allSaleProducts;
    }
    if (params.from === 'kitsProducts') {
      this.allKitsProducts = await this.conn.findKitsProducts();
      this.allKitsProducts.sort((element1: any, element2: any): number => {
        if (element1.get('ranking') === element2.get('ranking')) {
          return 0;
        }
        return element1.get('ranking') > element2.get('ranking') ? 1 : -1;
      });
      products = this.allKitsProducts;
    }
    if (params.from === 'holiSale') {
      this.holiSaleProducts = await this.conn.findHoliSaleProds();
      products = this.holiSaleProducts;
    }
    if (params.from === 'lipProducts') {
      products = await this.conn.findSectionProductsServerAPI(
        this.appConfig.Shared.ShopSections.MARKETING_TAGS.MT_SMOKER,
        true,
      );
    }
    if (params.from === 'bodyProducts') {
      products = await this.conn.findSectionProductsServerAPI(
        this.appConfig.Shared.ShopSections.MARKETING_TAGS.MT_BODY,
        true,
      );
    }
    if (params.from === 'scalpProducts') {
      const [hairFallProds, dandruffProds]: [Array<Models.Catalog>, Array<Models.Catalog>] = await Promise.all([
        this.conn.findSectionProductsServerAPI(
          this.appConfig.Shared.ShopSections.MARKETING_TAGS.MT_HAIR_FALL,
          true,
        ),
        this.conn.findSectionProductsServerAPI(
          this.appConfig.Shared.ShopSections.MARKETING_TAGS.MT_DANDRUFF,
          true,
        ),
      ]);
      products = dandruffProds.concat(hairFallProds);
    }
    if (params.from === 'reorderProducts') {
      tags = ['RE_ORDER'];
      products = await this.conn.findSectionProductsServerAPI(
        this.appConfig.Shared.ShopSections.TYPE.ReOrder,
        true,
      );
    }
    if (params.from === 'recommendedProducts') {
      tags = ['ADD_ON', 'RE_ORDER'];
      products = await this.conn.findSectionProductsServerAPI(
        this.appConfig.Shared.ShopSections.TYPE.Recommended,
        true,
      );
    }
    if (params.from === 'bogoProduct' && this.isBOGOSaleLive) {
      products = await this.fetchBogoProducts();
    }
    if (params.from === 'drRecommendedProducts') {
      tags = ['DOCTOR_ADDED'];
    }
    if (params.from !== 'search' && !this.refactoredSections.includes(params.from)) {
      if (this.isBOGOSaleLive || this.isHoliSaleLive) {
        // eslint-disable-next-line max-len
        if (!params.tags
          && params.from !== 'saleProducts'
          && params.from !== 'holiSale' && params.from !== 'kitsProducts' && params.from !== 'bogoProduct') {
          if (params.showAll && !tags) {
            tags = ['ADD_ON', 'RE_ORDER'];
          }
          products = await this.conn.findAllProductsForUser(tags, true);
        }
      } else if (!params.tags) {
        if (params.showAll && !tags) {
          tags = ['ADD_ON', 'RE_ORDER'];
          excludeTag = ['BOGO_PRODUCT'];
        }
        products = await this.conn.findAllProductsForUser(tags, true, excludeTag);
      }

      products = JSON.parse(JSON.stringify(products));

      if (params.from !== 'reorderProducts' && !params.showAll && params.from !== 'bogoProduct'
        && params.from !== 'drRecommendedProducts') {
        products.forEach((element: any, index: any): any => {
          const tagsList = element.tags;
          if (tagsList?.includes('RE_ORDER')) {
            if (!tagsList.includes('ADD_ON')) {
              products.splice(index, 1);
            }
          }
        });
      }
    }
    if (params && params.from) {
      this.eventLogger.cleverTapEvent('pageOpen', JSON.stringify({ pageName: 'product-list' }));
      if (params.from === 'reorderProducts') this.toolbarTitle = 'Reorder Products';
      else if (params.from === 'recommendedProducts') this.toolbarTitle = 'Recommended Products';
      else if (params.from === 'bogoProduct') this.toolbarTitle = 'Sale Products';
      else if (params.from === 'holiSale') this.toolbarTitle = 'Holi Products';
      else if (params.from === 'lipProducts') this.toolbarTitle = 'Lips Products';
      else if (params.from === 'scalpProducts') this.toolbarTitle = 'Scalp Products';
      else if (params.from === 'bodyProducts') this.toolbarTitle = 'Body Products';
      else if (params.from === 'kitsProducts') this.toolbarTitle = 'Kits Products';
      else if (params.from === 'drRecommendedProducts') this.toolbarTitle = 'Suggested by your doctor';
    }
    const productsFromUrl: string[] = params.products ? params.products.split(',') : [];
    if (productsFromUrl.length) {
      let productsFromParams = await this.conn.findProductsById(productsFromUrl);
      productsFromParams = JSON.parse(JSON.stringify(productsFromParams));
      productsFromParams.forEach((each: any): void => {
        const isProductFoundFromUrl: boolean = !!products.find((product: any): boolean => product.objectId === each.objectId);
        if (!isProductFoundFromUrl) products.push(each);
        else this.productsInCart[each.objectId] = 1;
      });
    }
    let productsFromParams = await this.conn.findProductsById(productsFromUrl);
    productsFromParams = JSON.parse(JSON.stringify(productsFromParams));
    if (productsFromParams.length === 1) {
      const found = this.cartItems.some((el: any): any => el.productId === productsFromUrl[0]);
      if (!found) {
        const params1 = {
          productId: productsFromParams[0].objectId,
          quantity: 1,
        };
        await this.conn.addProductToCart(params1);
        this.getCart();
      }
    }
    if (this.cartItems.length) {
      this.cartItems.forEach((each: any): void => {
        let isProductFoundFromUrl: boolean;
        if (tags && !tags.includes('ADD_ON')) {
          isProductFoundFromUrl = !!products.find((product: any): boolean => (product.objectId === each.productId
            && product?.tags?.includes('RE_ORDER')));
          this.productsInCart[each.productId] = each.quantity;
        } else {
          this.productsInCart[each.productId] = each.quantity;
        }
      });
    }
    if (!this.cartItems.length && !productsFromParams.length) {
      this.productsInCart = {};
    }
    if (this.productsInCart && this.cartItems.length && !productsFromParams.length) {
      this.productsInCart = {};
      const result = this.cartItems.filter((o1: any): any => products.some((o2: any): any => o2.objectId === o1.productId));
      result.forEach((element: any): any => {
        this.productsInCart[element.productId] = element.quantity;
      });
    }
    products.sort((valueA: any, valueB: any): number => {
      if (this.productsInCart[valueA.objectId] && !this.productsInCart[valueB.objectId]) return -1;
      return 1;
    });
    this.store.dispatch(fromActions.CartUpdateProductsBegin({ products: this.productsInCart }));
    // Remove sale products from reorder products
    // products = JSON.parse(JSON.stringify(products));

    if (params.from === 'reorderProducts') {
      products = products.filter((prod: any): boolean => !prod.isSaleProduct && prod.margUnit !== 2);
    }
    if (params.from === 'recommendedProducts') {
      products = products.filter((prod: any): boolean => !prod.isSaleProduct && prod.margUnit !== 2);
    }
    if (this.isPersonalizedBogoExperiment && params.from === 'bogoProduct') {
      products = products.filter((prod: any): boolean => prod.type !== 'main');
    }
    if (params.from !== 'reorderProducts') {
      products = products.filter((prod: any): boolean => prod.inventoryStatus !== this.appConfig.Shared.Inventory.Type.DISCONTINUED);
    }
    this.productsAvailable = products;
    this.cart.get('lineItems').forEach((product: any): void => {
      this.totalMRP += product.quantity * product.mrp;
    });
    this.totalMRP = Math.round(this.totalMRP);
    this.totalSP = Math.round(this.cart.get('totalPrice'));
    this.calculateDiscount();
  }

  async fetchBogoProducts(): Promise<any> {
    const saleProds = await this.conn.findBogoSaleProds();
    const availableSaleProducts = [];
    saleProds?.forEach((element: any): any => {
      availableSaleProducts.push(element.get('product'));
    });
    availableSaleProducts.filter((product:any):any => product.get('inventoryStatus') === 'AVAILABLE');
    availableSaleProducts?.sort((element1: any, element2: any): number => {
      if (element1.get('ranking') === element2.get('ranking')) {
        return 0;
      }
      return element1.get('ranking') > element2.get('ranking') ? 1 : -1;
    });
    return availableSaleProducts;
  }
  /**
   * We add or remove product in cart
   * More than 3 quantity can't be added for rx product.
   * More than 5 quantity can't be added for non rx product.
   * Recalculate total & discount.
   */
  // eslint-disable-next-line complexity
  async addOrRemoveFromCart(index: number, action: string, event: any, img?: any): Promise<void> {
    event.stopPropagation();
    const product = this.productsAvailable[index];
    this.oldProduct = this.productsAvailable[index];
    const quantity = this.productsInCart[product.objectId] || 0;
    if (action === 'ADD' && product.inventoryStatus === this.appConfig.Shared.Inventory.Type.DISCONTINUED) {
      let alternateProduct = await this.conn.findCatalogWithAlternateProduct(this.oldProduct.objectId);
      alternateProduct = JSON.parse(JSON.stringify(alternateProduct));
      if (alternateProduct.alternateProduct) {
        this.newProduct = alternateProduct.alternateProduct;
        this.isDiscontinued = true;
        return;
      }
    }
    if (action === 'ADD' && product.prescriptionRequired && quantity === this.rxLimit) {
      this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 3 per product' });
      return;
    }
    if (action === 'ADD' && !product.prescriptionRequired && quantity === this.nonRxLimit) {
      this.broadcast.broadcast('NOTIFY', { message: 'Maximum quantity is limited to 5 per product' });
      return;
    }
    if (action === 'ADD' && !product.prescriptionRequired && quantity < this.nonRxLimit) {
      this.animateAddToCart(img);
      // this.animateAddToCartProduct(product);
      this.totalMRP += (Number(this.productsAvailable[index].mrp));
      this.totalSP += (Number(this.productsAvailable[index].price));
      this.productsInCart[product.objectId] = quantity + 1;
      const arr1 = Object.keys(this.productsInCart);
      const commonElements = this.cartItems.filter((element: any): any => arr1.includes(element.productId));
      const tempData = { ...this.productsInCart };
      commonElements.forEach((each: any): any => {
        tempData[each.productId] = each.quantity;
      });
      const params = {
        productId: this.productsAvailable[index].objectId,
        quantity: tempData[product.objectId] ? 1 : 0,
      };
      await this.conn.addProductToCart(params);
      if (this.searchProducts.length > 0) {
        this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'search-add-to-cart', value: this.productsAvailable[index].id }));
      }
      this.getCart();
    }
    if (action === 'ADD' && product.prescriptionRequired && quantity < this.rxLimit) {
      this.animateAddToCart(img);
      // this.animateAddToCartProduct(product);
      this.totalMRP += (Number(this.productsAvailable[index].mrp));
      this.totalSP += (Number(this.productsAvailable[index].price));
      this.productsInCart[product.objectId] = quantity + 1;
      const arr1 = Object.keys(this.productsInCart);
      const commonElements = this.cartItems.filter((element: any): any => arr1.includes(element.productId));
      const tempData = { ...this.productsInCart };
      commonElements.forEach((each: any): any => {
        tempData[each.productId] = each.quantity;
      });
      const params = {
        productId: this.productsAvailable[index].objectId,
        quantity: tempData[product.objectId] ? 1 : 0,
      };
      await this.conn.addProductToCart(params);
      if (this.searchProducts.length > 0) {
        this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'search-add-to-cart', value: this.productsAvailable[index].id }));
      }
      this.getCart();
    }

    if (action === 'REMOVE') {
      this.totalMRP -= (Number(this.productsAvailable[index].mrp));
      this.totalSP -= (Number(this.productsAvailable[index].price));
      this.productsInCart[product.objectId] = quantity - 1;
      const params = {
        productId: this.productsAvailable[index].objectId,
        quantity: 1,
      };
      await this.conn.removeProductFromCart(params);
      this.getCart();
    }
    this.store.dispatch(fromActions.CartUpdateProductsBegin({ products: this.productsInCart }));
    this.calculateDiscount();
  }

  animateAddToCartProduct(product: any): void {
    this.animationProduct = product;
    setTimeout((): void => {
      this.animationProduct = null;
    }, 1000);
  }

  animateAddToCart(img?: HTMLImageElement): void {
    const image = new Image();
    image.src = img.src;
    image.style.position = 'absolute';
    image.style.top = `${img.getBoundingClientRect().top}px`;
    image.style.right = `${this.windowRef.nativeWindow.innerWidth - img.getBoundingClientRect().right}px`;
    image.style.height = `${img.offsetHeight}px`;
    image.style.width = 'auto';
    image.style.zIndex = '1000';

    image.classList.add('fly-animation');
    image.onanimationend = ((event: any): void => {
      image.remove();
    });
    this.windowRef.nativeWindow.document.body.appendChild(image);
  }

  async viewProduct(product: any): Promise<void> {
    if (product.inventoryStatus === this.appConfig.Shared.Inventory.Type.DISCONTINUED) {
      const tempProduct = await this.conn.findCatalogWithAlternateProduct(product.objectId);
      const alternateProduct = JSON.parse(JSON.stringify(tempProduct));
      if (alternateProduct?.alternateProduct) {
        this.oldProduct = product;
        this.newProduct = alternateProduct?.alternateProduct;
        this.isDiscontinued = true;
        return;
      }
    }
    this.localStorage.setValue('scrollID', product.objectId);
    const productData = JSON.parse(JSON.stringify(product));
    if (this.searchProducts.length > 0) {
      this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'search-product', value: productData.objectId }));
      await this.router.navigate([`/product/${productData.objectId}`],
        { queryParams: { section: 'searchProduct' } });
    }
    await this.conn.navigateToURL(`/product/${productData.objectId}`);
  }

  calculateDiscount(): void {
    let discount = 0;
    this.totalMRP = Math.floor(this.totalMRP);
    this.totalMRP = Math.floor(this.totalMRP);
    if (this.totalMRP > this.totalSP) {
      discount = Math.floor(((this.totalMRP - this.totalSP) * 100) / this.totalMRP);
    }
    this.discount = discount;
  }

  showError(message: any): void {
    this.broadcast.broadcast('NOTIFY', { message });
  }

  // TODO : support sending quantities selected to api and create order for specified quantity.
  /**
   * Send all selected products id in queryParam to checkout page.
   * Note: We are now not sending quantity to backend. We have to support this feat soon. Right now its all considered as 1 quantity.
   */
  checkout(): void {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'product-list-buy-now' }));
    this.eventLogger.trackEvent('reorder_buy_now', { username: this.user.get('username') });
    this.eventLogger.trackInElasticSearch({ username: this.user.get('username'),
      added: new Date(),
      type: 'webApp',
      message: 'User clicked reorder buy ',
      event: 'REORDER_BUY' });

    if (this.cartItems.length === 1) {
      // Transform {productId: quantity} to a string containing `quantity` times `productId`
      // eg. {'abc': 2, 'xyz': 1} => 'abc,abc,xyz'
      const products = Object.entries(this.productsInCart)
        .filter((product: any[]): boolean => product[1] > 0)
        .reduce((pre: string, curr: any[]): string => pre + `${curr[0]},`.repeat(curr[1]), '')
        .replace(/(^,)|(,$)/g, '');
      const url = 'user/checkout';
      const queryParams = { type: this.appConfig.Shared.Order.Type.PRODUCT, products };
      this.navigateTo(url, { queryParams });
    } else if (this.cartItems.length > 1) {
      this.router.navigate(['cart']);
    }
  }

  navigateTo(url: string, params?: Record<string, any>): void {
    this.router.navigate([url], params);
  }

  back(): void {
    this.router.navigate(['/user'], { queryParams: { tab: 'shop', back: 'home' } });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => subscription.unsubscribe());
  }

  closeDialog(): void {
    this.selfReorderWarning = false;
  }

  closePopup(isReplace: boolean = false): void {
    this.isDiscontinued = false;
    this.broadcast.broadcast('NAVIGATION_BACK');
    if (isReplace) {
      this.isReplaced = true;
    }
  }

  updateCache(): void {
    const existingReorderCache = this.cacheService.retrieve('CureSkin/reorderProducts');
    const existingShopCache = this.cacheService.retrieve('CureSkin/shopSection');
    if (existingReorderCache) {
      const cachedReorder = existingReorderCache.data.result;
      for (let i = 0; i < cachedReorder.length; i += 1) {
        const currentReorderCache = cachedReorder[i];
        if (currentReorderCache.objectId === this.oldProduct.objectId) {
          cachedReorder[i] = this.newProduct;
          break;
        }
      }
      const updatedReorderCache = JSON.parse(JSON.stringify(existingReorderCache));
      updatedReorderCache.data.result = cachedReorder;
      this.cacheService.store('CureSkin/reorderProducts', updatedReorderCache);
    }

    if (existingShopCache) {
      const cachedShop = existingShopCache.data.result.ReOrder;
      for (let i = 0; i < cachedShop.length; i += 1) {
        const currentShopCache = cachedShop[i];
        if (currentShopCache.objectId === this.oldProduct.objectId) {
          cachedShop[i] = this.newProduct;
          break;
        }
      }
      const updatedShopCache = JSON.parse(JSON.stringify(existingShopCache));
      updatedShopCache.data.result.ReOrder = cachedShop;
      this.cacheService.store('CureSkin/shopSection', updatedShopCache);
    }
  }

  async changeProduct(): Promise<void> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'discontinued-product-changed' }));
    this.loading = true;
    const res = await this.conn.replaceDiscontinuedProduct(this.oldProduct.objectId, this.newProduct.objectId);
    this.updateCache();
    this.isDiscontinued = false;
    if (res) {
      const params = {
        productId: this.newProduct.objectId,
        quantity: 1,
      };
      const cart = await this.conn.addProductToCart(params);
      if (cart) {
        await this.getCart();
        this.isDiscontinued = false;
        this.isReplaced = true;
        await this.loadData();
      }
    }
    this.loading = false;
  }

  async openArticle(): Promise<any> {
    this.eventLogger.cleverTapEvent('click', JSON.stringify({ name: 'discontinued-product-changed' }));
    await this.conn.replaceDiscontinuedProduct(this.oldProduct.objectId, this.newProduct.objectId);
    this.updateCache();
    this.isDiscontinued = false;
    this.isReplaced = true;
    const params = this.route.snapshot.queryParams;
    let query: any = '';
    if (params.from) {
      query = `from=${params.from}`;
    } else {
      query = 'showAll=true';
    }
    this.location.replaceState('user/order/reorder', query);
    this.viewProduct(this.newProduct);
  }
  checkoutAOV(): void {
    if (this.toolbarIcons[0].cartValue) {
      this.router.navigate(['cart']);
    }
  }
}
